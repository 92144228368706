<template>
  <div class="modal__wrapper">
	<div class="modal__close">
	  <div class="btn" @click="close">
		<Close class="img"/>
	  </div>
	</div>
	<div class="modal__header">
	  <div class="ttl">Add Expense</div>
	</div>
	<div class="modal__body">
	  <div class="row">
		<div class="label">Monthly expense</div>
		<div class="data c-tmblr">
		  <Tmblr :active="!!form.is_monthly" :class="'c-tmblr'" :text="'Monthly expense'" @changeState="changeState('is_monthly')" />
		</div>
	  </div>
	  <div class="row">
		  <div class="label">Name</div>
		  <div class="data">
			<input type="text" name="name" class="inpt" v-model="form.name">
		  </div>
		</div>
		<div class="row">
			<div class="label">Description</div>
			<div class="data">
			  <textarea name="description" rows="5" class="inpt inpt__ta" v-model="form.description"/>
			</div>
		  </div>
	  <div class="row">
		<div class="label">Type</div>
		<div class="data">
		  <select name="status" class="inpt" v-model="form.type">
			<option :value="null">Select type</option>
			<option
			  v-for="type in types" :key="type"
			  :selected="type === form.type"
			  :value="type"
			>
			  {{ type }}
			</option>
		  </select>
		</div>
	  </div>
	  <div class="row">
		  <div class="label">Main amount</div>
		  <div class="data">
			<input type="number" @change="calculateTotalAmount" name="amount" class="inpt" v-model="form.amount">
		  </div>
		</div>
		<div class="row">
		  <div class="label">Additional amount</div>
		  <div class="data">
			<input type="number" @change="calculateTotalAmount" name="additional_amount" class="inpt" v-model="form.additional_amount">
		  </div>
		</div>
		<div class="row">
		  <div class="label">Total amount</div>
		  <div class="data">
			<input type="number" name="additional_amount" class="inpt" v-model="form.total_monthly">
		  </div>
		</div>
	  <div class="row" v-if="!form.is_monthly">
		  <div class="label">Payment date</div>
		  <div class="data">
			<input type="date" name="payment_date" class="inpt" v-model="form.payment_date">
		  </div>
	  </div>
	  <div class="row">
		  <div class="label">Payment method</div>
		  <div class="data">
			<select name="status" class="inpt" v-model="form.payment_method">
			  <option :value="null">Select payment</option>
			  <option
				v-for="paymentMethod in paymentMethods" :key="paymentMethod"
				:selected="paymentMethod === form.payment_method"
				:value="paymentMethod"
			  >
				{{ paymentMethod }}
			  </option>
			</select>
		  </div>
	  </div>
	  <div class="row row_files">
		<div class="box box_addFiles">
		  <div v-if="getUploadedFiles('documents').length" class="content content_many">
			<draggable v-model="upload.documents" tag="div" class="draggable">
			<div v-for="(file, key) in getUploadedFiles('documents')" :key="key" class="file">
			  <BlueFile class="img" />
			  <div class="btn btn_del" @click="delFile('documents', key, file)"><Close class="img" /></div>
			  <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'documents')"><BlueDownload class="img" /></div>
			  <div class="desc">{{ file.name }}</div>
			</div>
			</draggable>
			<div class="add">
			  <div class="btn" @click="$refs.documents[upload.documents.length - 1].click()"><Close class="img" /></div>
			</div>
		  </div>
		  <div v-else class="content">
			<div class="choose">
			  <div class="btn" @click="$refs.documents[0].click()">License document</div>
			</div>
		  </div>
			<input
			  v-for="(i, key) in upload.documents"
			  :key="key"
			  type="file"
			  :name="`documents_${key}`"
			  class="hide"
			  ref="documents"
			  @change="addInput($event, 'documents', key)"
			>
		</div>
		<div class="box box_addFiles">
		  <div v-if="getUploadedFiles('bill').length" class="content content_many">
			<draggable v-model="upload.bill" tag="div" class="draggable">
			<div v-for="(file, key) in getUploadedFiles('bill')" :key="key" class="file">
			  <BlueFile class="img" />
			  <div class="btn btn_del" @click="delFile('bill', key, file)"><Close class="img" /></div>
			  <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'bill')"><BlueDownload class="img" /></div>
			  <div class="desc">{{ file.name }}</div>
			</div>
			</draggable>
			<div class="add">
			  <div class="btn" @click="$refs.bill[upload.bill.length - 1].click()"><Close class="img" /></div>
			</div>
		  </div>
		  <div v-else class="content">
			<div class="choose">
			  <div class="btn" @click="$refs.bill[0].click()">Bill</div>
			</div>
		  </div>
			<input
			  v-for="(i, key) in upload.bill"
			  :key="key"
			  type="file"
			  :name="`bill_${key}`"
			  class="hide"
			  ref="bill"
			  @change="addInput($event, 'bill', key)"
			>
		</div>
	  </div>
	</div>
	<div class="modal__footer">
	  <div class="btn" @click="save">Save</div>
	</div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
	Close,
	Tmblr,
	BlueDownload,
	BlueFile,
	draggable,
  },
  props: {
	options: {
	  type: Object,
	  default: null
	}
  },
  data(){
	return{
	  countries: [],
	  managers: [],
	  asystents: [],
	  fintechTypes: [],
	  types: [
		'STAFF',
		'Marketing',
		'Soft',
		'Tool',
		'Admin',
		'Other'
	  ],
	  paymentMethods: [
		  'IBAN',
		  'Crypto',
		  'Card'
		],
	  form: {
		is_monthly: false,
		type: null,
		name: null,
		description: null,
		amount: null,
		additional_amount: null,
		total_monthly: null,
		payment_date: null,
		payment_method: null,
	  },
	  error: null,
	  upload: {
		documents: [{ name: null }],
		bill: [{ name: null }],
	  }
	}
  },
  computed: {
	isEdit () {
	  return this.options && this.options.id && this.options.form
	}
  },
  async created () {
	if(this.isEdit){
	  await this.getExpanse()
	  await this.getDocuments()
	  await this.getBill()
	}
  },
  methods: {
	async getDocuments () {
	  const that = this
	  const id = this.options.id
	  
	  if(id){
		await axios.get('admin/expenses/' + id + '/get-file/documents')
		.then((response) => {
		  let docsData = response.data

		  if(docsData.length > 0) {
			that.upload.documents = docsData.map((el) => {
			  return {
				id: el.id,
				uuid: el.uuid,
				name: el.name,
				type: 'server',
				url: el.original_url,
				order: el.order
			  }
			})
		  }
		})
		.catch((errors) => {
		  console.dir(errors)
		})
	  }
	},
	async getBill () {
	  const that = this
	  const id = this.options.id
	  
	  if(id){
		await axios.get('admin/expenses/' + id + '/get-file/bill')
		.then((response) => {
		  let docsData = response.data

		  if(docsData.length > 0) {
			that.upload.bill = docsData.map((el) => {
			  return {
				id: el.id,
				uuid: el.uuid,
				name: el.name,
				type: 'server',
				url: el.original_url,
				order: el.order
			  }
			})
		  }
		})
		.catch((errors) => {
		  console.dir(errors)
		})
	  }
	},
	getUploadedFiles(el) {
	  return this.upload[el].filter(e => e.name !== null)
	},
	delFile(name, key, file) {
	  if (this.upload[name].length > 1) {
		this.upload[name].splice(key, 1)
	  } else {
		this.upload[name][0].name = null
	  }

	  if(file.id && this.options.id){
		axios.post('admin/expenses/' + this.options.id +'/delete-file', {
		collection: name,
		media_id: file.id,
	  })
		.then(function () {
		  
		})
		.catch(function (error) {
		  console.log(error);
		})
	  }
	},
	addInput(event, name, key) {
	  if (this.$refs[name][key].files.length) {
		const file_name = this.$refs[name][key].files[0].name
		if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
		  this.$noty.error('Error! A file with this name has already been added.')
		  return false
		}

		const extension = this.$refs[name][key].files[0].name.split('.').pop()
		const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
		if (!availableExtensionsArr.includes(extension.toLowerCase())) {
		  this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
		  return false
		}

		if (this.$refs[name][key].files[0].size / 1024 / 1024 > 10) {
		  this.$noty.error('File is too large. Maximum - 10MB.')
		  return false
		}

		this.upload[name] = this.upload[name].filter(e => e.name !== null)
		if (this.$refs[name].length < 10) {
		  this.upload[name].push({ name: file_name, file: event.target.files[0]})
		} else {
		  this.$noty.error('Maximum - 10 files')
		}
	  }
	},
	downloadFile(file, type) {
	  const id = this.options.id
	  if(file.url){
		this.openFile(file.url)
	  }
	  else if(id){
		axios.post('admin/expenses/' + id +'/get-file-link', {
		collection: type,
		media_id: file.id,
	  })
		.then(function (response) {
		  if (response.data) {
			const url = response.data
			var fileURL = response.data;
			this.openFile(fileUrl);
		  }
		})
		.catch(function (error) {
		  console.log(error);
		})
	  }
	},
	openFile(url){
	  var fileLink = document.createElement('a');
	  fileLink.href = url;
	  fileLink.setAttribute('target', '_blank')
	  document.body.appendChild(fileLink);
	  fileLink.click();
	},
	async getExpanse(){
	  const that = this
	  await axios.get('admin/expenses/' + this.options.id)
	  .then(function (response) {
		that.form = response.data
	  })
	  .catch(function (error) {
		console.log(error);
	  })
	 },
	async save(){
	  let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/expenses'

	  if (this.isEdit) {
		url += '/' + this.options.id
	  }

	  await axios.post(url, this.form)
		.then((response) => {
		  this.close();

		  this.processFiles(response.data.expense.id)

		  if(this.isEdit){
			this.$store.dispatch('editItemFromTable', {
			  id: this.options.id,
			  table: this.options.table,
			  actionType: this.options.actionType,
			})
		  }
		  else{
			this.$store.dispatch('createItemInTable', {
			  table: this.options.table,
			  actionType: this.options.actionType,
			})
		  }
		})
		.catch((errors) => {
		  console.dir(errors)
		})
	},
	processFiles(id){
	  let data = new FormData()
	  const uploadKeys = Object.keys(this.upload)

	  for (let z = 0; z < uploadKeys.length; z++) {
		const key = uploadKeys[z]
		for (let i = 0; i < this.upload[key].length; i++) {
		  if (this.upload[key][i].name !== null) {
			let f = {}
			if (this.upload[key][i].type == 'server') {
			  f = JSON.stringify(this.upload[key][i])
			} else {
			  data.append(key + '_names[' + i + ']', this.upload[key][i].name)
			  f = this.upload[key][i].file
			}
			data.append(key + '[' + i + ']', f)
		  }
		}
	  }
	  
	  axios.post('admin/expenses/' + id + '/files', data, {
		headers: {
		  'Content-Type': 'multipart/form-data',
		}
	  }).then((response) => {
		this.close()
	  })
	  .catch((errors) => {
		console.dir(errors)
	  })
	},
	changeState(property){
	  this.form[property] = !this.form[property]
	},
	calculateTotalAmount(){
		this.form.total_monthly = +this.form.amount + +this.form.additional_amount;
	},
	close() {
	  this.$emit("close");
	}
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
	width: 40rem;
  }

  &__header {
	padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
	flex-direction: column;
	align-content: flex-start;

	.row {
	  width: 100%;
	  display: flex;
	  align-items: center;
	  padding: .25rem 0;
	  margin: .25rem 0 0;

	  .label {
		min-width: 10rem;
		padding-right: .75rem;
		font-weight: bold;
		max-width: 160px;
	  }

	  .data {
		flex-grow: 1;

		.inpt {
		  width: 100%;
		  color: #000000;

		  &__ta {
			height: 7rem;
		  }
		}
		.btn {
		  padding: 0.5rem;
		  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
		  border-radius: 6px;
		  color: #fff;
		  font-size: 13px;
		}
		.delete-btn{
		  margin-left: auto;
		  width: 24px;
		  height: 24px;
		  background-color: #B03636;
		  border-radius: 50%;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  cursor: pointer;
		  svg{
			fill: #fff;
			max-width: 16px;
			max-height: 16px;
		  }
		}
	  }

	  &__price {
		.data {
		  display: flex;

		  input {
			margin-right: .75rem;
		  }

		  select {
			color: white;
			background-color: var(--c_more-black);
			padding: .25rem .5rem;
			border-radius: 6px;
		  }
		}
	  }
	}
	.row{
	  &_files {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 8px;

		.box_addFiles {
		  width: 30%;
		  //width: calc(50% - .75rem);
		  //margin: .75rem 0;
		  //padding: 1rem 1.75rem;
		  //background: #22252B;
		  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
		  display: flex;
		  flex-direction: column;

		  .ttl {
			padding-bottom: .25rem;
		  }

		  .separ {
			width: 3rem;
			height: .25rem;
			background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
			box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
			border-radius: 14px;
			margin: .25rem 0;
		  }

		  .content {
			padding: 1rem;
			flex-grow: 1;
			//margin-top: 1rem;
			border: 1px dashed #1763fb;
			box-sizing: border-box;
			filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
			border-radius: 14px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			.choose {
			  text-align: center;

			  .btn {
				padding: 0.5rem;
				background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
				border-radius: 6px;
				color: #fff;
				font-size: 13px;
			  }

			  .desc {
				font-size: 12px;
				color: rgba(255, 255, 255, 0.3);
			  }
			}

			&_many {
			  flex-wrap: wrap;
			  align-items: flex-start;
			  justify-content: space-between;
			  padding: 0.75rem 6px;

			  .draggable {
				display: flex;
				padding: 0;
				flex-wrap: wrap;
			  }

			  .file {
				position: relative;
				text-align: center;
				width: 4.5rem;
				max-width: 4.5rem;

				svg.img {
				  width: 3rem;
				  height: 3rem;
				  margin: 0 auto;
				  cursor: pointer;
				  fill: #1763fb;
				  path{
					fill: #1763fb;
				  }
				}
				.btn {
				  &_del {
					position: absolute;
					top: 0;
					right: 1rem;
					width: 1.25rem;
					height: 1.25rem;
					border-radius: 50%;
					background-color: #B03636;
					display: flex;
					align-items: center;
					justify-content: center;

					svg.img {
					  width: 40%;
					  height: 40%;
					}
				  }

				  &_download {
					position: absolute;
					top: 1.7rem;
					right: 1rem;
					width: 1.25rem;
					height: 1.25rem;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;

					svg.img {
					  width: 90%;
					  height: 90%;
					}
				  }
				}

				.desc {
				  font-size: 10px;
				  color: #333333;
				  word-break: break-word;
				}
			  }

			  .add {
				text-align: center;
				width: 2.5rem;
				max-width: 2.5rem;

				.btn {
				  width: 2.5rem;
				  height: 2.5rem;
				  border-radius: 50%;
				  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
				  display: flex;
				  align-items: center;
				  justify-content: center;

				  svg.img {
					width: 45%;
					height: 45%;
					transform: rotate(45deg);
				  }
				}

				.desc {
				  font-size: 12px;
				  color: rgba(255, 255, 255, 0.3);
				}
			  }
			}
		  }
		}
	  }
	}
  }
  &__footer {
	padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
	height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
