import { render, staticRenderFns } from "./LeadAddToBalanceModal.vue?vue&type=template&id=48539fdb&scoped=true&"
import script from "./LeadAddToBalanceModal.vue?vue&type=script&lang=js&"
export * from "./LeadAddToBalanceModal.vue?vue&type=script&lang=js&"
import style0 from "./LeadAddToBalanceModal.vue?vue&type=style&index=0&id=48539fdb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48539fdb",
  null
  
)

export default component.exports