<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Task</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Task</div>
        <div class="data">
          <input type="text" name="task" class="inpt" v-model="form.task">
        </div>
      </div>
      <div class="row" v-if="$store.state.user.is_super_admin">
        <div class="label">Manager can see</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_manager_can" :class="'c-tmblr'" :text="'Manager can see'" @changeState="changeState('is_manager_can')" />
        </div>
      </div>
      <div class="row">
        <div class="label">Customer name</div>
        <div class="data">
          <input type="text" name="client_name" class="inpt" v-model="form.client_name">
        </div>
      </div>
      <div class="row" v-if="$store.state.user.is_super_admin">
        <div class="label">Date of task</div>
        <div class="data">
          <input type="datetime-local" name="tasked_at" class="inpt" v-model="form.tasked_at">
        </div>
      </div>
      <div class="row">
        <div class="label">Task description</div>
        <div class="data">
          <textarea name="task_description" rows="5" class="inpt inpt__ta" v-model="form.task_description"/>
        </div>
      </div>
      <div class="row" v-if="form.id">
        <div class="label">Task comments</div>
        <div class="data">
          <span class="btn" @click="showTaskComment">
            Show task comment
          </span>
        </div>
      </div>
      <div class="row">
        <div class="label">Priority</div>
        <div class="data">
          <select name="priority" class="inpt" v-model="form.priority">
            <option :value="null">Select priority</option>
            <option
              v-for="priority in priorities" :key="priority"
              :selected="priority === form.priority"
              :value="priority"
            >
              {{ priority }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Status</div>
        <div class="data">
          <select name="status" class="inpt" v-model="form.status">
            <option :value="null">Select status</option>
            <option
              v-for="status in statuses" :key="status"
              :selected="status === form.status"
              :value="status"
            >
              {{ status }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Task links</div>
        <div class="data">
          <div v-for="(link, i) in form.task_links">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Link № {{ i + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteTaskLink(i)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row gap-12">
                <div class="col">
                  <input type="text" name="service_name" class="inpt" v-model="link.service_name" placeholder="Name of link">
                </div>
                <div class="col">
                  <input type="text" name="link" class="inpt" v-model="link.link" placeholder="Link">
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addTaskLink">
                  Add task link
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Responsible manager</div>
        <div class="data">
          <div v-for="(formResponsibleManager, formResponsibleManagerIndex) in form.responsible_managers">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Responsible Manager № {{ formResponsibleManagerIndex + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteResponsibleManager(formResponsibleManagerIndex)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <select name="manager_name" class="inpt" v-model="formResponsibleManager.name">
                <option :value="null">Select manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addResponsibleManager">
                  Add responsible manager
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Control manager</div>
        <div class="data">
          <div v-for="(formControlManager, formControlManagerIndex) in form.control_managers">
          <div>
            <div class="row">
            <div class="data">
              <div class="row">
              <div class="label">
                Responsible Manager № {{ formControlManagerIndex + 1 }}
              </div>
              <div class="data">
                <div class="delete-btn" @click="deleteControlManager(formControlManagerIndex)">
                <Close class="img"/>
                </div>
              </div>
              </div>
            </div>
            </div>
          </div>
          <div>
            <select name="manager_name" class="inpt" v-model="formControlManager.name">
              <option :value="null">Select manager</option>
              <option 
                v-for="(manager, i) in managers" 
                :value="manager.name"
              >
                {{ manager.name }}
              </option>
            </select>
          </div>
          </div>
          <div>
          <div class="row">
            <div class="data">
            <span class="btn" @click="addControlManager">
              Add control manager
            </span>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Assistant</div>
        <div class="data">
          <select name="asystent_name" class="inpt" v-model="form.asystent_name">
            <option :value="null">Select assistant</option>
            <option
              v-for="item in asystents" :key="item.name"
              :selected="item.name === form.asystent_name"
              :value="item.name"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" v-if="$store.state.user.is_super_admin || $store.state.user.is_super_manager">
        <div class="label">Deadline at</div>
        <div class="data">
          <input type="datetime-local" name="deadline_at" class="inpt" v-model="form.deadline_at">
        </div>
      </div>
      <div class="row" v-if="(form.status == 'Close Win' || form.status == 'Close Lost')">
        <div class="label">Closed at</div>
        <div class="data">
          <input type="datetime-local" name="closed_at" class="inpt" v-model="form.closed_at">
        </div>
      </div>
      <div class="row row_files">
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('documents').length" class="content content_many">
            <draggable v-model="upload.documents" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('documents')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('documents', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'documents')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.documents[upload.documents.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.documents[0].click()">Task document</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.documents"
              :key="key"
              type="file"
              :name="`documents_${key}`"
              class="hide"
              ref="documents"
              @change="addInput($event, 'documents', key)"
            >
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data(){
    return{
      countries: [],
      managers: [],
      asystents: [],
      statuses: [
        'Active',
        'In Progress',
        'On Hold',
        'Close Win',
        'Close Lost'
      ],
      priorities: [
        'High (1 hour)',
        'Middle (day)',
        'Low (week)',
      ],
      form: {
        task: null,
        client_name: null,
        tasked_at: null,
        task_description: null,
        status: null,
        priority: null,
        is_manager_can: true,
        responsible_manager_name: null,
        control_manager_name: null,
        asystent_name: null,
        deadline_at: null,
        task_links: [],
        task_links_to_delete: [],
        responsible_managers: [],
        control_managers: [],
      },
      error: null,
      upload: {
        documents: [{ name: null }],
      }
    }
  },
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    }
  },
  async created () {
    if(this.isEdit){
      await this.getTask()
      await this.updateFormData()
      await this.getDocuments()
    }
    
    await this.getManagers()
    await this.getAsystents()
  },
  methods: {
    changeState(property){
      this.form[property] = !this.form[property]
    },
    addTaskLink(){
      this.form.task_links.push({
        service_name: null,
        link: null
      })
    },
    showTaskComment(){
      this.$store.commit('setModal', {
        template: 'task-comments',
        options: {
          form: this.form,
          id: this.form.id,
          actionType: 'edit',
          table: 'tasks',
          objectType: 'task',
          successfullMessage: 'You successfully add comments',
        }
      })
    },
    deleteTaskLink(index){
      if(this.form.task_links[index].hasOwnProperty('id')){
        this.form.task_links_to_delete.push(this.form.task_links[index])
      }

      this.form.task_links.splice(index, 1)
    },
    addResponsibleManager(){
      this.form.responsible_managers.push({
        name: null
      })
    },
    deleteResponsibleManager(index){
      this.form.responsible_managers.splice(index, 1)
    },
    addControlManager(){
      this.form.control_managers.push({
        name: null
      })
    },
    deleteControlManager(index){
      this.form.control_managers.splice(index, 1)
    },
    updateFormData(){
      let arrResponsibleManagers = [];
      let arrControlManagers = [];
      
      if(this.form.responsible_manager_name){
        arrResponsibleManagers = this.form.responsible_manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      if(this.form.control_manager_name){
        arrControlManagers = this.form.control_manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      this.$set(this.form, 'responsible_managers',  arrResponsibleManagers)
      this.$set(this.form, 'control_managers',  arrControlManagers)
    },
    async getDocuments () {
      const that = this
      const id = this.options.id
      
      if(id){
        await axios.get('admin/tasks/' + id + '/get-file/documents')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0) {
            that.upload.documents = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getUploadedFiles(el) {
      return this.upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.upload[name].length > 1) {
        this.upload[name].splice(key, 1)
      } else {
        this.upload[name][0].name = null
      }

      if(file.id && this.options.id){
        axios.post('admin/tasks/' + this.options.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 10) {
          this.$noty.error('File is too large. Maximum - 10MB.')
          return false
        }

        this.upload[name] = this.upload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 10) {
          this.upload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.options.id
      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/tasks/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async getTask(){
      const that = this
      await axios.get('admin/tasks/' + this.options.id)
      .then(function (response) {
        that.form = response.data
        that.form.task_links_to_delete = []
        that.clientBudgetKey++
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
	  },
    async getAsystents () {
      const that = this
      await axios.get('admin/data/asystents')
        .then(function (response) {
          that.asystents = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async save(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/tasks'

      if (this.isEdit) {
        url += '/' + this.options.id
      }

      this.form.responsible_manager_name = this.form.responsible_managers.map(obj => obj.name).join('|');
      this.form.control_manager_name = this.form.control_managers.map(obj => obj.name).join('|');
      
      if(!this.$store.state.user.is_super_admin){
        this.form.is_manager_can = true
      }
      
      await axios.post(url, this.form)
        .then((response) => {
          this.close();

          this.processFiles(response.data.task.id)

          if(this.isEdit){
            this.$store.dispatch('editItemFromTable', {
              id: this.options.id,
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
          else{
            this.$store.dispatch('createItemInTable', {
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.upload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.upload[key].length; i++) {
          if (this.upload[key][i].name !== null) {
            let f = {}
            if (this.upload[key][i].type == 'server') {
              f = JSON.stringify(this.upload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.upload[key][i].name)
              f = this.upload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/tasks/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    changeState(property){
      this.form[property] = !this.form[property]
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
