<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Add to balance internal deal</div>
    </div>
    <div class="modal__body">
      <!-- OLD LEAD BALANCE VERSION
        <div class="row">
          <div class="label">Total amount</div>
          <div class="data">
            <input type="number" @change="calculateTotalBalance" name="balance_total_amount" class="inpt" v-model="form.lead.balance_total_amount">
          </div>
        </div>
        <div class="row">
          <div class="label">Seller amount</div>
          <div class="data">
            <input type="number" @change="calculateTotalBalance" name="balance_seller_amount" class="inpt" v-model="form.lead.balance_seller_amount">
          </div>
        </div>
        <div class="row">
          <div class="label">Agent amount</div>
          <div class="data">
            <input type="number" @change="calculateTotalBalance" name="balance_agent_amount" class="inpt" v-model="form.lead.balance_agent_amount">
          </div>
        </div>
        <div class="row">
          <div class="label">Team amount</div>
          <div class="data">
            <input type="number" @change="calculateTotalBalance" name="balance_team_amount" class="inpt" v-model="form.lead.balance_team_amount">
          </div>
        </div>
        <div class="row">
          <div class="label">M&A income</div>
          <div class="data">
            <input type="number" name="balance_income_amount" class="inpt" v-model="form.lead.balance_income_amount">
          </div>
        </div>
        <div class="row">
          <div class="label">Paid balance</div>
          <div class="data">
            <input type="number" name="paid_balance" class="inpt" v-model="form.lead.paid_balance">
          </div>
        </div>
        <div class="row">
          <div class="label">Payment type</div>
          <div class="data">
            <select name="balance_payment_type" class="inpt" v-model="form.lead.balance_payment_type" :key="paymentTypesKey">
              <option :value="null">Select type</option>
              <option
                v-for="(type, i) in paymentTypes" :key="type.value"
                :selected="type.value == form.lead.balance_payment_type"
                :value="type.value"
              >
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="label">Income Deadline</div>
          <div class="data">
            <input type="date" name="income_deadline_at" class="inpt" v-model="form.lead.income_deadline_at">
          </div>
        </div>
      -->
      <div class="row">
        <div class="data" style="max-width:100%">
          <div class="inner-balance-data">
            <div>
              <div class="row gap-12 balance-row">
                <div class="col">
                  Service
                </div>
                <div class="col">
                  Execution period
                </div>
                <div class="col">
                  Client price
                </div>
                <div class="col">
                  Seller price
                </div>
                <div class="col">
                  Profit
                </div>
                <div class="col">
                  Profitability
                </div>
                <div class="col">
                  M&A Team
                </div>
                <div class="col">
                  NET Profit
                </div>
                <div class="col">
                  NET Profit (per month)
                </div>
              </div>
            </div>
            <div v-for="(item, i) in form.lead_balance_items">
              <div>
                <div class="row  balance-row services gap-12">
                  <div class="col balance-item-delete">
                    <div class="delete-btn" @click="deleteLeadBalanceItem(i)">
                      <Close class="img"/>
                    </div>
                  </div>
                  <div class="col">
                    <select name="service" class="inpt" v-model="item.service">
                      <option :value="null">Select service</option>
                      <option
                        v-for="(service, i) in services" :key="service"
                        :selected="service == item.service"
                        :value="service"
                      >
                        {{ service }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <input type="text" placeholder="Execution period" name="execution_period" class="inpt" v-model="item.execution_period" @change="calculateItemNetProfitPerMonth(i)">
                  </div>
                  <div class="col">
                    <input type="text" @change="calculateItemBalance(i)" placeholder="Client price" name="public_price" class="inpt" v-model="item.public_price">
                  </div>
                  <div class="col">
                    <input type="text" @change="calculateItemBalance(i)" placeholder="Seller price" name="net_cost" class="inpt" v-model="item.net_cost">
                  </div>
                  <div class="col">
                    <input type="text" readonly placeholder="Profit" name="profit" class="inpt" v-model="item.profit">
                  </div>
                  <div class="col">
                    <input type="text" readonly placeholder="Profitability" name="profitability" class="inpt" v-model="item.profitability">
                  </div>
                  <div class="col">
                    <input type="text" @change="calculateItemNetProfit(i); calculateTotalTeamBalanceByItems()" placeholder="M&A Team" name="team_amount" class="inpt" v-model="item.team_amount">
                  </div>
                  <div class="col">
                    <input type="text" readonly placeholder="NET Profit" name="net_profit" class="inpt" v-model="item.net_profit">
                  </div>
                  <div class="col">
                    <input type="text" readonly placeholder="NET Profit (per month)" name="net_profit_per_month" class="inpt" v-model="item.net_profit_per_month">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addLeadBalanceItem">
                  Add service
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Client Price</div>
        <div class="data">
          <input type="number" @change="calculateTotalBalance()" name="total_public_price" class="inpt" v-model="form.lead_balance.total_public_price">
        </div>
      </div>
      <div class="row">
        <div class="label">Seller price</div>
        <div class="data">
          <input type="number" @change="calculateTotalBalance()" name="total_net_cost" class="inpt" v-model="form.lead_balance.total_net_cost">
        </div>
      </div>
      <div class="row">
        <div class="label">Profit</div>
        <div class="data">
          <input type="number" readonly name="total_profit" class="inpt" v-model="form.lead_balance.total_profit">
        </div>
      </div>
      <div class="row">
        <div class="label">Profitability</div>
        <div class="data">
          <input type="number" readonly name="total_profitability" class="inpt" v-model="form.lead_balance.total_profitability">
        </div>
      </div>
      <div class="row">
        <div class="label">Agent amount</div>
        <div class="data">
          <input type="number" @change="calculateTotalNetProfitBalance" name="agent_amount" class="inpt" v-model="form.lead_balance.agent_amount">
        </div>
      </div>
      <div class="row">
        <div class="label">Team amount</div>
        <div class="data">
          <input type="number" @change="calculateTotalBalance()" name="total_team_amount" class="inpt" v-model="form.lead_balance.total_team_amount">
        </div>
      </div>
      <div class="row">
        <div class="label">NET Profit</div>
        <div class="data">
          <input type="number" readonly name="total_net_profit" class="inpt" v-model="form.lead_balance.total_net_profit">
        </div>
      </div>
      <div class="row">
        <div class="label">NET Profit (per month)</div>
        <div class="data">
          <input type="number" readonly name="net_profit_per_month" class="inpt" v-model="form.lead_balance.net_profit_per_month">
        </div>
      </div>
      <div class="row">
        <div class="label">Paid balance</div>
        <div class="data">
          <input type="number" name="paid_balance" class="inpt" v-model="form.lead.paid_balance">
        </div>
      </div>
      <div class="row">
        <div class="label">Execution period (month)</div>
        <div class="data">
          <input type="number" name="execution_period" class="inpt" v-model="form.lead_balance.execution_period"  @change="calculateNetProfitPerMonth()">
        </div>
      </div>
      <div class="row">
        <div class="label">Execution</div>
        <div class="data">
          <div v-for="(execution, executionIndex) in form.lead_balance.executions">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Execution № {{ executionIndex + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteExecution(executionIndex)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <select name="execution" class="inpt" v-model="execution.name">
                <option :value="null">Select execution</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addExecution">
                  Add execution
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Status</div>
        <div class="data">
          <select name="status" class="inpt" v-model="form.lead_balance.status">
            <option :value="null">Select status</option>
            <option
              v-for="(status, i) in statuses" :key="status"
              :selected="status == form.lead_balance.status"
              :value="status"
            >
              {{ status }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Buyer channel</div>
        <div class="data">
          <input type="text" name="buyer_channel" class="inpt" v-model="form.lead_balance.buyer_channel">
        </div>
      </div>
      <div class="row">
        <div class="label">Seller channel</div>
        <div class="data">
          <input type="text" name="seller_channel" class="inpt" v-model="form.lead_balance.seller_channel">
        </div>
      </div>
      <div class="row">
        <div class="label">Agent name</div>
        <div class="data">
          <input type="text" name="agent_name" class="inpt" v-model="form.lead_balance.agent_name">
        </div>
      </div>
      <div class="row">
        <div class="label">Payment type</div>
        <div class="data">
          <select name="balance_payment_type" class="inpt" v-model="form.lead.balance_payment_type" :key="paymentTypesKey">
            <option :value="null">Select type</option>
            <option
              v-for="(type, i) in paymentTypes" :key="type.value"
              :selected="type.value == form.lead.balance_payment_type"
              :value="type.value"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Deal started</div>
        <div class="data">
          <input type="date" name="started_at" class="inpt" v-model="form.lead_balance.started_at">
        </div>
      </div>
      <div class="row">
        <div class="label">Deal closed</div>
        <div class="data">
          <input type="date" name="closed_at" class="inpt" v-model="form.lead_balance.closed_at">
        </div>
      </div>
      <div class="row">
        <div class="label">Income Deadline</div>
        <div class="data">
          <input type="date" name="income_deadline_at" class="inpt" v-model="form.lead.income_deadline_at">
        </div>
      </div>
      <div class="row row_files">
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('documents').length" class="content content_many">
            <draggable v-model="upload.documents" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('documents')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('documents', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'documents')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.documents[upload.documents.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.documents[0].click()">License document</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.documents"
              :key="key"
              type="file"
              :name="`documents_${key}`"
              class="hide"
              ref="documents"
              @change="addInput($event, 'documents', key)"
            >
        </div>
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('bill').length" class="content content_many">
          <draggable v-model="upload.bill" tag="div" class="draggable">
          <div v-for="(file, key) in getUploadedFiles('bill')" :key="key" class="file">
            <BlueFile class="img" />
            <div class="btn btn_del" @click="delFile('bill', key, file)"><Close class="img" /></div>
            <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'bill')"><BlueDownload class="img" /></div>
            <div class="desc">{{ file.name }}</div>
          </div>
          </draggable>
          <div class="add">
            <div class="btn" @click="$refs.bill[upload.bill.length - 1].click()"><Close class="img" /></div>
          </div>
          </div>
          <div v-else class="content">
          <div class="choose">
            <div class="btn" @click="$refs.bill[0].click()">Bill</div>
          </div>
          </div>
          <input
            v-for="(i, key) in upload.bill"
            :key="key"
            type="file"
            :name="`bill_${key}`"
            class="hide"
            ref="bill"
            @change="addInput($event, 'bill', key)"
          >
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div v-if="confirmDelete" class="options">
        <center>
          <h3>Are you sure you want delete this internal deal from balance</h3>
          <div style="max-width: 240px; margin:auto">
            <div class="btn btn__save" @click="deleteLeadBalance">Yes, delete</div>
            <div class="btn btn__delete" @click="confirmDelete = false">NO</div>
          </div>
        </center>
      </div>
      <div v-else class="options">
        <div class="btn btn__save" @click="save">Save</div>
        <div class="btn btn__delete" @click="confirmDelete = true">Delete</div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    BlueFile,
    draggable,
    BlueDownload,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: function(){
    return{
      paymentTypes: [
        {
          name: 'Fiat',
          value: 'fiat'
        },
        {
          name: 'Crypto',
          value: 'crypto'
        }
      ],
      managers: [],
      statuses: [
        'In progress',
        'Pre-payment',
        'Closed'
      ],
      services: [
        'License',
        'BaaS',
        'Accounts',
        'Director outstaff',
        'AML outstaff',
        'N5Bank',
        'Other'
      ],
      form: {
        lead: {
          balance_total_amount: 0,
          balance_seller_amount: 0,
          balance_agent_amount: 0,
          balance_team_amount: 0,
          balance_income_amount: 0,
          balance_payment_type: 0,
          balance: 0,
          paid_balance: 0,
          income_deadline_at: '',
        },
        lead_balance: {
          total_public_price: 0,
          total_net_cost: 0,
          total_provider: 0,
          total_profit: 0,
          total_profitability: 0,
          total_team_amount: 0,
          agent_amount: 0,
          total_net_profit: 0,
          net_profit_per_month: 0,
          execution_period: 1,
          status: '',
          buyer_channel: '',
          seller_channel: '',
          agent_name: '',
          execution: '',
          executions: [],
          started_at: '',
          closed_at: '',
          lead_id: this.options.id,
        },
        lead_balance_items: [],
        lead_balance_items_to_delete: []
      },
      paymentTypesKey: 0,
      error: null,
      confirmDelete: false,
      upload: {
        documents: [{ name: null }],
        bill: [{ name: null }],
      }
    }
  },
  async mounted() {
    await this.getManagers()
    await this.getLead()
    await this.getLeadBalance()
    await this.getDocuments()
    await this.getBill()
  },
  methods: {
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getLead(){
      let that = this
      
      await axios.get('admin/leads/' + this.options.id)
      .then(function (response) {
        that.form.lead = response.data
        that.form.lead.is_balances = true
        that.paymentTypesKey++
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getLeadBalance(){
      let that = this
      
      await axios.get('admin/lead-balances/get-lead-balance/' + this.options.id)
      .then(function (response) {
        if(response.data){
          that.form.lead_balance = response.data
          that.form.lead_balance_items = response.data.lead_balance_items
        } else{
          that.form.lead_balance.execution = that.form.lead.manager_name
          that.form.lead_balance.started_at = that.form.lead.joined_at
          that.form.lead_balance.agent_amount = that.form.lead.agent_fee
          that.form.lead_balance.agent_name = that.form.lead.agent_name
          
          that.addLeadBalanceItem()
        }
        
        let arrExecutions = [];
        
        if(that.form.lead_balance.execution){
          arrExecutions = that.form.lead_balance.execution.split('|').map(name => {
            return { name: name };
          });
        }
        
        that.$set(that.form.lead_balance, 'executions',  arrExecutions)
        
        that.paymentTypesKey++
      })
      .catch(function (error) {
        console.log(error);
      })
    },
    async getDocuments () {
      const that = this
      const id = this.form.lead_balance.id
      
      if(id){
        await axios.get('admin/lead-balances/' + id + '/get-file/documents')
        .then((response) => {
          let docsData = response.data
    
          if(docsData.length > 0) {
            that.upload.documents = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    async getBill(){
      const that = this
      const id = this.form.lead_balance.id
      
      if(id){
        await axios.get('admin/lead-balances/' + id + '/get-file/bill')
        .then((response) => {
          let docsData = response.data
    
          if(docsData.length > 0) {
            that.upload.bill = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getUploadedFiles(el) {
      return this.upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.upload[name].length > 1) {
        this.upload[name].splice(key, 1)
      } else {
        this.upload[name][0].name = null
      }
    
      if(file.id && this.options.id){
        axios.post('admin/leads/' + this.options.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }
    
        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }
    
        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 10) {
          this.$noty.error('File is too large. Maximum - 10MB.')
          return false
        }
    
        this.upload[name] = this.upload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 10) {
          this.upload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.options.id
      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/leads/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.upload)
    
      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.upload[key].length; i++) {
          if (this.upload[key][i].name !== null) {
            let f = {}
            if (this.upload[key][i].type == 'server') {
              f = JSON.stringify(this.upload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.upload[key][i].name)
              f = this.upload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/lead-balances/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    calculateTotalBalance(){
      this.form.lead_balance.total_profit = parseFloat((this.form.lead_balance.total_public_price - this.form.lead_balance.total_net_cost).toFixed(2));
      this.form.lead_balance.total_profitability = parseFloat(((this.form.lead_balance.total_profit / this.form.lead_balance.total_public_price) * 100).toFixed(2));
      this.form.lead_balance.total_net_profit = parseFloat((this.form.lead_balance.total_profit - this.form.lead_balance.total_team_amount - this.form.lead_balance.agent_amount).toFixed(2));
      this.form.lead_balance.net_profit_per_month = parseFloat((this.form.lead_balance.total_net_profit / this.form.lead_balance.execution_period).toFixed(2));
    },
    calculateItemBalance(index){
      let item = this.form.lead_balance_items[index]
      
      item.profit = parseFloat((item.public_price - item.net_cost).toFixed(2));
      item.profitability = parseFloat(((item.profit / item.public_price) * 100).toFixed(2));
      item.team_amount = parseFloat((item.profit / 100 * 10).toFixed(2));
      item.net_profit = parseFloat((item.profit - item.team_amount).toFixed(2));
      item.net_profit_per_month = parseFloat((item.net_profit / item.execution_period).toFixed(2));
      
      this.calculateTotalBalanceByItems()
    },
    calculateTotalBalanceByItems(){
      this.form.lead_balance.total_public_price = 0;
      this.form.lead_balance.total_net_cost = 0;
      this.form.lead_balance.total_team_amount = 0;
      
      this.form.lead_balance_items.forEach((item) => {
        this.form.lead_balance.total_public_price += Math.round(item.public_price * 100) / 100;
        this.form.lead_balance.total_net_cost += Math.round(item.net_cost * 100) / 100;
        this.form.lead_balance.total_team_amount += Math.round(item.team_amount * 100) / 100;
      });
      
      this.form.lead_balance.total_profit = parseFloat((this.form.lead_balance.total_public_price - this.form.lead_balance.total_net_cost).toFixed(2));
      this.form.lead_balance.total_profitability = parseFloat(((this.form.lead_balance.total_profit / this.form.lead_balance.total_public_price) * 100).toFixed(2));
      this.form.lead_balance.total_net_profit = parseFloat((this.form.lead_balance.total_profit - this.form.lead_balance.total_team_amount - this.form.lead_balance.agent_amount).toFixed(2));
      this.form.lead_balance.net_profit_per_month = parseFloat((this.form.lead_balance.total_net_profit / this.form.lead_balance.execution_period).toFixed(2));
    },
    calculateItemNetProfit(index){
      let item = this.form.lead_balance_items[index]
      
      item.net_profit = parseFloat((item.profit - item.team_amount).toFixed(2));
      item.net_profit_per_month = parseFloat((item.net_profit / item.execution_period).toFixed(2));
    },
    calculateItemNetProfitPerMonth(index){
      let item = this.form.lead_balance_items[index]
      
      item.net_profit_per_month = parseFloat((item.net_profit / item.execution_period).toFixed(2));
    },
    calculateNetProfitPerMonth(){
      this.form.lead_balance.net_profit_per_month = parseFloat((this.form.lead_balance.total_net_profit / this.form.lead_balance.execution_period).toFixed(2));
    },
    calculateTotalTeamBalanceByItems(){
      this.form.lead_balance.total_team_amount = 0;
      
      this.form.lead_balance_items.forEach((item) => {
        this.form.lead_balance.total_team_amount += Math.round(item.team_amount * 100) / 100;
      });
      
      this.form.lead_balance.total_net_profit = parseFloat((this.form.lead_balance.total_profit - this.form.lead_balance.total_team_amount - this.form.lead_balance.agent_amount).toFixed(2));
      this.form.lead_balance.net_profit_per_month = parseFloat((this.form.lead_balance.total_net_profit / this.form.lead_balance.execution_period).toFixed(2));
    },
    calculateTotalNetProfitBalance(){
      this.form.lead_balance.total_net_profit = parseFloat((this.form.lead_balance.total_profit - this.form.lead_balance.total_team_amount - this.form.lead_balance.agent_amount).toFixed(2));
      this.form.lead_balance.net_profit_per_month = parseFloat((this.form.lead_balance.total_net_profit / this.form.lead_balance.execution_period).toFixed(2));
    },
    async deleteLeadBalance() {
      this.form.lead.is_balances = false;
      this.form.lead.balance_total_amount = 0;
      this.form.lead.balance_seller_amount = 0;
      this.form.lead.balance_agent_amount = 0;
      this.form.lead.balance_team_amount = 0;
      this.form.lead.balance_income_amount = 0;
      this.form.lead.balance_payment_type = '';
      this.form.lead.balance = 0;
      this.form.lead.paid_balance = 0;
      this.form.lead.income_deadline_at = '';
      
      await this.saveLead()
      
      let leadBalanceUrl = process.env.VUE_APP_BACKEND_API_URL + 'admin/lead-balances'
      
      if(this.form.lead_balance.id){
        leadBalanceUrl += '/' + this.form.lead_balance.id
      }
      
      await axios.delete(leadBalanceUrl)
        .then((response) => {
          this.close();
          
          this.$store.dispatch('editItemFromTable', {
            id: this.options.id,
            table: this.options.table,
            actionType: this.options.actionType,
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    updateLeadDataBeforeSave(){
      this.form.lead.is_balances = true;
      this.form.lead.balance_total_amount = this.form.lead_balance.total_public_price;
      this.form.lead.balance_seller_amount = this.form.lead_balance.total_net_cost;
      this.form.lead.balance_agent_amount = this.form.lead_balance.agent_amount;
      this.form.lead.balance_team_amount = this.form.lead_balance.total_team_amount;
      this.form.lead.balance_income_amount = this.form.lead_balance.total_net_profit;
      this.form.lead.balance = this.form.lead_balance.total_public_price;
    },
    addLeadBalanceItem(){
      this.form.lead_balance_items.push({
        public_price: 0,
        net_cost: 0,
        provider: 0,
        profit: 0,
        profitability: 0,
        net_profit: 0,
        net_profit_per_month: 0,
        team_amount: 0,
        execution_period: 1,
        service: this.services[0],
      })
    },
    deleteLeadBalanceItem(index){
      if(this.form.lead_balance_items[index].hasOwnProperty('id')){
        this.form.lead_balance_items_to_delete.push(this.form.lead_balance_items[index])
      }
    
      this.form.lead_balance_items.splice(index, 1)
      this.calculateTotalBalanceByItems()
    },
    addExecution(){
      this.form.lead_balance.executions.push({
        name: null
      })
    },
    deleteExecution(index){
      this.form.lead_balance.executions.splice(index, 1)
    },
    async saveLead(){
      let leadUrl = process.env.VUE_APP_BACKEND_API_URL + 'admin/leads'
      
      leadUrl += '/' + this.options.id
      
      await axios.post(leadUrl, this.form.lead)
        .then((response) => {
          
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    async save(){
      this.updateLeadDataBeforeSave();
      
      await this.saveLead()
        
      let leadBalanceUrl = process.env.VUE_APP_BACKEND_API_URL + 'admin/lead-balances'
      
      if(this.form.lead_balance.id){
        leadBalanceUrl += '/' + this.form.lead_balance.id
      }
      
      this.form.lead_balance.execution = this.form.lead_balance.executions.map(obj => obj.name).join('|');
      
      let data = { ...this.form.lead_balance, lead_balance_items: this.form.lead_balance_items, lead_balance_items_to_delete: this.form.lead_balance_items_to_delete }
      
      await axios.post(leadBalanceUrl, data)
        .then((response) => {
          this.processFiles(response.data.leadBalance.id)
      
          this.close();
          
          this.$store.dispatch('editItemFromTable', {
            id: this.options.id,
            table: this.options.table,
            actionType: this.options.actionType,
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;
    .inner-balance-data{
      overflow: scroll;
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;
      &.balance-row{
        max-width: 100%;
        .col{
          min-width: 150px;
          width: 50%;
        }
      }
      .balance-item-delete{
        position: absolute;
        width: 24px !important;
        min-width: 24px !important;
        left: 12px;
        .delete-btn{
          margin-left: 0 !important;
        }
      }

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
    .btn__delete{
      background: #B03636;
    }
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
