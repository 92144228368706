<template>
  <div class="tbl">
    <div class="tbl_header">
      <div
        v-for="(i, key) in options.header"
        :key="key"
        class="col"
        :class="i.class ? i.class : ''"
        :style="{ 'width': options.colsWidth[key] }"
      ><span>
        {{ i.ttl }}
        <Info v-if="i.info" :pos="i.info.pos" :txt="i.info.txt" />
        <span
          v-if="i.sort"
          class="sort"
          :class="{
            'sort--load': options.sort.loading,
            'sort--asc': options.sort.name === i.sort && options.sort.order === 'asc',
            'sort--desc': options.sort.name === i.sort && options.sort.order === 'desc',
          }"
          @click="sortBy(i.sort)"
        >&#9650;</span>
      </span></div>
    </div>
    <div class="tbl_body">
      <div v-for="(i, key) in options.body" :key="key" class="tbl_row" :style="{ 'cursor': chatAppliedOfferType ? 'pointer' : '' }" @click="clickRow(key)">
        <div
          v-for="(el, el_key) in i"
          :key="`w_${el_key}`"
          class="col"
          :style="{ 'width': getCellWidth(el, el_key) }"
        >
          <div v-if="getType(el) === 'object' && el.type === 'edit-delete-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-expense-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-toggle-can-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
            <span v-if="$store.state.user.is_super_admin">
              <Tmblr :active="!!el.is_manager_can" :class="'c-tmblr'" :text="''" @changeState="toggleProperty(el.id, 'is_manager_can')" />
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-super-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span v-if="$store.state.user.is_super_admin" @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'verified_id'" class="box_btns">
            <span v-if="el.is_verified" style="color:green">
              {{ el.id }}
            </span>
            <span v-else style="color:red">
              {{ el.id }}
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-super-delete-super-actions'" class="box_btns">
            <span v-if="$store.state.user.is_super_admin" @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span v-if="$store.state.user.is_super_admin" @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-super-delete-super-toggle-can-actions'" class="box_btns">
            <span v-if="$store.state.user.is_super_admin" @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span v-if="$store.state.user.is_super_admin">
              <Tmblr :active="!!el.is_manager_can" :class="'c-tmblr'" :text="''" @changeState="toggleProperty(el.id, 'is_manager_can')" />
            </span>
            <span v-if="$store.state.user.is_super_admin" @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-super-comments-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span v-if="$store.state.user.is_super_admin" @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
            <span @click="showComments(el.id)" class="btn btn-edit">
              <Chat class="img"/>
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-super-comments-toggle-can-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="showComments(el.id)" class="btn btn-edit">
              <Chat class="img"/>
            </span>
            <span v-if="$store.state.user.is_super_admin">
              <Tmblr :active="!!el.is_manager_can" :class="'c-tmblr'" :text="''" @changeState="toggleProperty(el.id, 'is_manager_can')" />
            </span>
            <span v-if="$store.state.user.is_super_admin" @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'show-process-steps'">
            <span @click="showProcessSteps(el.value)">
              <InfoImg class="img" />
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-matches-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="matchItem(el.id)" class="btn btn-edit"><Match class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-matches-balance-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="matchItem(el.id)" class="btn btn-edit"><Match class="img"/></span>
            <span v-if="$store.state.user.is_super_admin" @click="addToBalanceItem(el.id)" class="btn btn-edit"><Calculator class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-matches-balance-toggle-can-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="matchItem(el.id)" class="btn btn-edit"><Match class="img"/></span>
            <span v-if="$store.state.user.is_super_admin" @click="addToBalanceItem(el.id)" class="btn btn-edit"><Calculator class="img"/></span>
            <span v-if="$store.state.user.is_super_admin">
              <Tmblr :active="!!el.is_manager_can" :class="'c-tmblr'" :text="''" @changeState="toggleProperty(el.id, 'is_manager_can')" />
            </span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-matches-toggle-can-actions'" class="box_btns">
            <span @click="matchItem(el.id)" class="btn btn-edit btn-match">
              <Match class="img"/>
              <span class="match-count" v-if="el.business_offers_count">
                  {{ el.business_offers_count }}
              </span>
            </span>
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span v-if="$store.state.user.is_super_admin">
              <Tmblr :active="!!el.is_manager_can" :class="'c-tmblr'" :text="''" @changeState="toggleProperty(el.id, 'is_manager_can')" />
            </span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'add-balance-actions'" class="box_btns">
            <span v-if="$store.state.user.is_super_admin" @click="addToBalanceItem(el.id)" class="btn btn-edit"><Calculator class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'seperator'" class="box_btns">
            <span>
              |
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'select-manager'">
            <select name="manager" class="inpt" v-model="el.lead_business_offer.manager_id" @change="selectManager(el)">
              <option :value="null">Select manager</option>
              <option 
                v-for="(manager, i) in el.managers" 
                :value="manager.id"
              >
                {{ manager.name }}
              </option>
            </select>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-new-delete-request-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit">
              <Pen class="img"/>
            </span>
            <span v-if="el.newOffer" @click="newItemVersion(el.id, el.newOffer)" class="btn btn-edit">
              <Eye class="img"/>
            </span>
            <span @click="deleteItem(el.id)" class="btn btn-del decorate-circle-wrap">
              <Close class="img"/>
              <span v-if="el.isRequestToDelete" class="decorate-circle">
              </span>
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-new-delete-request-pdf-toggle-top-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit">
              <Pen class="img"/>
            </span>
            <span v-if="el.newOffer" @click="newItemVersion(el.id, el.newOffer)" class="btn btn-edit">
              <Eye class="img"/>
            </span>
            <span v-if="el.pdf_url" @click="downloadPdfItem(el.pdf_url)" class="btn btn-edit"><Download class="img"/></span>
            <span @click="toggleProperty(el.id, 'is_top')" class="btn btn-edit" style="padding: 0">
              <Star v-if="!el.is_top" class="img big-img"/>
              <FilledStar v-else class="img big-img"/>
            </span>
            <span @click="deleteItem(el.id)" class="btn btn-del decorate-circle-wrap">
              <Close class="img"/>
              <span v-if="el.isRequestToDelete" class="decorate-circle">
              </span>
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-new-delete-request-pdf-toggle-top-toggle-verified-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit">
              <Pen class="img"/>
            </span>
            <span v-if="el.newOffer" @click="newItemVersion(el.id, el.newOffer)" class="btn btn-edit">
              <Eye class="img"/>
            </span>
            <span v-if="el.pdf_url" @click="downloadPdfItem(el.pdf_url)" class="btn btn-edit"><Download class="img"/></span>
            <span @click="toggleProperty(el.id, 'is_top')" class="btn btn-edit" style="padding: 0">
              <Star v-if="!el.is_top" class="img big-img"/>
              <FilledStar v-else class="img big-img"/>
            </span>
            <span>
              <Tmblr :active="!!el.is_verified" :class="'c-tmblr'" :text="''" @changeState="toggleProperty(el.id, 'is_verified')" />
            </span>
            <span @click="deleteItem(el.id)" class="btn btn-del decorate-circle-wrap">
              <Close class="img"/>
              <span v-if="el.isRequestToDelete" class="decorate-circle">
              </span>
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'toggle-validated-star-el'">
            <span @click="toggleProperty(el.id, 'is_validated')" class="btn btn-edit" style="padding: 0">
              <Star v-if="!el.is_validated" class="img big-img"/>
              <FilledStar v-else class="img big-img"/>
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-pdf-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span v-if="el.pdf_url" @click="downloadPdfItem(el.pdf_url)" class="btn btn-edit"><Download class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'business-offer-detail'" class="box_btns">
            <span @click="showBusinessOfferDetail(el.id)" class="btn btn-edit"><Pen class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'business-offer-detail-with-user'" class="box_btns">
            <span @click="showBusinessOfferDetailWithUser(el.id, el.user_id)" class="btn btn-edit"><Pen class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'user-details-delete-actions'" class="box_btns">
            <span @click="gotoUserDetails(el.userId)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'request-change'" v-html="el.html"></div>
          <div @click="apply(el.id, el.applyType)" v-else-if="getType(el) === 'object' && el.type === 'apply-btn'" v-html="el.html"></div>
          <div v-else-if="getType(el) === 'object' && el.type === 'basic'" class="btn" v-html="el.html"></div>
          <div v-else-if="getType(el) === 'object' && el.type === 'status'">
            <div :style="'color:' + getStatusColor(el.value)">
              {{ el.value }}
            </div>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'priority'">
            <div :style="'color:' + getPriorityColor(el.value)">
              {{ el.value }}
            </div>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'strategy-priority'">
            <div :style="'color:' + getStrategyPriorityColor(el.value)">
              {{ el.value }}
            </div>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'deadline'" class="ddln">
            Deadline: <span class="ddln_val" :class="{'broken': el.days < 0}">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val" :class="{'broken': el.hours < 0}">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'transactions'">
            <div 
              v-for="(transaction, i) in el.transactions" 
              :key="i"
              class="transaction-item">
              <small>
                <a :href="transaction.media[0].original_url" v-if="transaction.media[0]">
                  {{ number_format(transaction.amount, 2, '.', ' ') }}
                </a>
                <span v-else>
                  {{ number_format(transaction.amount, 2, '.', ' ') }}
                </span>
              </small>
              <small>
                <span :style="{ color: (el.transactions.length == 1 && el.total >= el.price) ? 'green' : '' }">
                  {{ transaction.created_at | formatDateTime }}
                </span>
              </small>
            </div>
            <div v-if="el.transactions.length > 1 || Object.keys(el.transactions).length > 1">
              <small :style="{ color: +el.total >= +el.price ? 'green' : '' }">
                Total: {{ number_format(el.total, 2, '.', ' ') }} 
              </small>
            </div>
          </div>
          <template v-else-if="getType(el) === 'object' && el.type === 'deadline_legal'">
            <div class="ddln">
              Deadline: <span class="ddln_val" :class="{'broken': el.days < 0}">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val" :class="{'broken': el.hours < 0}">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>
            <div class="del ml-3">
              <div class="btn btn_close" @click="apply(el.id, el.applyType)"><Close /></div>
            </div>
          </template>
          <div v-else-if="getType(el) === 'object' && el.type === 'link'">
            <span :class="{'fake-link': el.link}" @click="goToUrl(el.link)">{{el.text ? el.text : 'web site'}}</span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'links'" style="max-width: 100%; word-wrap: break-word">
            <div v-for="(item, i) in el.value">
              <a :href="item.link">
                {{ item.name }}
              </a>
            </div>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'task-status'">
            <span :style="{ color: getTaskStatusColor(el.value) }">
              {{ el.value }}
            </span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'deadline_lite'" class="ddln">
            <span class="ddln_val" v-if="el.days" :class="{'broken': el.days < 0}">{{ el.days }}</span> <span class="ddln_desc" v-if="el.days">days</span> <span class="ddln_val" v-if="el.hours" :class="{'broken': el.hours < 0}">{{ el.hours }}</span> <span class="ddln_desc" v-if="el.hours">hours</span>
          </div>
          <span v-else-if="getType(el) === 'object' && el.type === 'info'" >
            {{ el.html }}
            <Info :pos="el.info.pos" :txt="el.info.txt" />
          </span>
          <div v-else-if="getType(el) === 'object' && el.type === 'rating'" class="rating-color" :style="{'background-color': el.rating_color, 'display': 'flex', 'align-items': 'center', 'justify-content': 'center'}">{{ el.rating }}
          </div>
          <div
            v-else-if="getType(el) === 'object' && el.type === 'rating_btn'"
            class="rating_btn"
            @click="$emit('rating-modal', i[0])"
          >
            <div
              v-if="el.rating || el.rating_color"
              class="rating_btn--data"
              :style="{ 'background-color': el.rating_color }"
              v-text="el.rating"
            />
            <div v-else class="btn rating_btn--empty"><Pen class="img"/></div>
          </div>
          <span v-else-if="getType(el) === 'object' && el.type === 'del'" class="del">
            <div class="btn btn_close"><Close /></div>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'notification'" class="notification">
            <span @click="showNotificationDetails(el.notificationType, el.notification)">{{ el.text }}</span>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'chat-request'" class="link">
            <span @click="showChatRequests(el.userId)">{{ el.count }}</span>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'show-chat'">
            <span @click="showChat(el.chatId)" class="btn btn-del"><Chat class="img"/></span>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'edit-link'">
            <span @click="editItemItem(el.id)">
              {{ el.text }}
            </span>
          </span>
          <template v-else-if="getType(el) === 'object' && el.type === 'deadline_info'">
            <div class="ddln">
              <span class="ddln_val" :class="{'broken': el.days < 0}">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val" :class="{'broken': el.hours < 0}">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'cancel'">
            <div class="del ml-3">
              <div class="btn btn_close" @click="apply(el.id, el.applyType)"><Close /></div>
            </div>
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'cancel-with-user'">
            <div class="del ml-3">
              <div class="btn btn_close" @click="applyWithUser(el.id, el.user_id, el.applyType)"><Close /></div>
            </div>
          </template>
          <span v-else-if="getType(el) === 'object' && el.type === 'more'" class="more">
            <div class="btn btn_more" @click="openMoreModal(i[0])"><More /></div>
          </span>
          <span
            v-else-if="getType(el) === 'object' && el.type === 'v_link'"
            class="link"
            v-html="el.html"
            @click="$router.push(el.link)"
          />
          <div @click="showUserBusinessOffers(el.id)" class="btn show-user-business-offers" v-else-if="getType(el) === 'object' && el.type === 'show-user-business-offers'" v-html="el.html"></div>
          <span v-else-if="getType(el) === 'object' && el.type === 'hidden-id'" style="display: none">
            {{ el.id }}
          </span>
          <span v-else >
            <truncate v-if="el" clamp="..." :length="60" less="Show Less" :text="el"></truncate>
          </span>
        </div>
      </div>
      <div v-if="options.footer && options.footer.type === 'Total Price' " class="tbl_row tbl_row-footer">
        <div class="tp">Total Price: <span class="val">{{ options.footer.val }}</span></div>
      </div>
      <div v-if="options.footer && options.footer.paid_type === 'Total showed paid' " class="tbl_row tbl_row-footer">
        <div class="tp">
          Total showed price: <span class="val" style="margin-right: 12px">{{ options.footer.val }}</span>

          Total showed paid: <span class="val">{{ options.footer.paid_val }}</span>
        </div>
      </div>
      <div v-else-if="options.footer && options.footer.type === 'Total showed price' " class="tbl_row tbl_row-footer">
        <div class="tp">Total showed price: <span class="val">{{ options.footer.val }}</span></div>
      </div>
    </div>
    <div class="tbl_footer">
      <pagination
        v-if="options.paginationData"
        :data="options.paginationData"
        @pagination-change-page="paginationChangePage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from 'laravel-vue-pagination'
import Info from '@/components/elem/Info.vue'
import Pen from '@/components/img/Pen.vue'
import Eye from '@/components/img/Eye.vue'
import Download from '@/components/img/Download.vue'
import Close from '@/components/img/Close.vue'
import Match from '@/components/img/Match.vue'
import Calculator from '@/components/img/Calculator.vue'
import Star from '@/components/img/Star.vue'
import FilledStar from '@/components/img/FilledStar.vue'
import Chat from '@/components/img/Chat.vue'
import More from '@/components/img/More'
import InfoImg from '@/components/img/Info'
import { mapMutations } from 'vuex';
import truncate from 'vue-truncate-collapsed'
import Tmblr from '../elem/Tmblr.vue'

export default {
  components: {
    Pagination,
    Info,
    Close,
    Match,
    Calculator,
    Pen,
    More,
    truncate, 
    Chat,
    Download,
    Eye,
    InfoImg,
    Star,
    FilledStar,
    Tmblr
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    chatAppliedOfferType: {
      type: String,
      required: false,
      default: ''
    },
  },
  data(){
    return {
      statuses: [
        {
          name: 'active',
          color: '#00ff00'
        },
        {
          name: 'not active',
          color: 'grey'
        },
        {
          name: 'pushed',
          color: 'orange'
        },
        {
          name: 'lost',
          color: '#ff0000'
        }
      ]
    }
  },
  methods: {
    ...mapMutations({
      setChatOffer: 'SET_CHAT_OFFER',
    }),
    getCellWidth(data, key) {
      let w = this.options.colsWidth[key]

      if (this.getType(data) === 'object' && data.type === 'deadline') {
        w = `calc(${this.options.colsWidth[key]} + ${this.options.colsWidth[key + 1]})`
      }

      if (this.getType(data) === 'object' && data.type === 'deadline_legal') {
        w = `calc(${this.options.colsWidth[key]} + ${this.options.colsWidth[key + 1]})`
      }

      return w
    },
    getStatusColor(status){
      if(status){
        status = status.toLowerCase().trim()

        for(let i = 0; i < this.statuses.length; i++){
          let name = this.statuses[i].name.toLowerCase().trim()

          let indexOf = status.indexOf(name)

          if(indexOf != -1 && indexOf == 0){
            return this.statuses[i].color
          }
        }

        for(let i = 0; i < this.statuses.length; i++){
          let name = this.statuses[i].name.toLowerCase().trim()
          
          if(status.includes(name)){
            return this.statuses[i].color
          }
        }
      }

      return ''
    },
    getTaskStatusColor(status) {
      switch (status) {
        case 'Active':
          return '#007bff';
        case 'In Progress':
          return '#fd7e14';
        case 'On Hold':
          return '#6c757d';
        case 'Close Win':
          return '#28a745';
        case 'Close Lost':
          return '#dc3545'
        default:
          return '#ffffff';
      }
    },
    getPriorityColor(priority) {
      switch (priority) {
        case 'High (1 hour)':
          return 'red';
        case 'Middle (day)':
          return 'orange';
        case 'Low (week)':
          return '#ffffff';
        default:
          return '#ffffff';
      }
    },
    getStrategyPriorityColor(priority) {
      switch (priority) {
        case 'High':
          return 'red';
        case 'Middle':
          return 'orange';
        case 'Low':
          return '#ffffff';
        default:
          return '#ffffff';
      }
    },
    apply (id, applyType) {
      this.$emit('apply', {
        id: id,
        applyType: applyType,
      })
    },
    applyWithUser(id, user_id, applyType) {
      this.$emit('apply-with-user', {
        id: id,
        user_id: user_id,
        applyType: applyType,
      })
    },
    deleteItem (id) {
      this.$emit('deleteItem', {
        id: id,
      })
    },
    matchItem (id) {
      this.$emit('matchItem', {
        id: id,
      })
    },
    addToBalanceItem(id){
      this.$emit('addToBalance', {
        id: id,
      })
    },
    selectManager(el){
      this.$emit('select-manager', {
        el: el,
      })
    },
    editItemItem (id) {
      this.$emit('editItem', {
        id: id,
      })
    },
    showBusinessOfferDetail(id){
      this.$emit('show-details', {
        id: id,
      })
    },
    showBusinessOfferDetailWithUser(id, user_id){
      this.$emit('show-details-with-user', {
        id: id,
        user_id: user_id,
      })
    },
    newItemVersion(id, newOffer){
      this.$emit('showNewVersion', {
        id: id,
        newOffer: newOffer
      })
    },
    toggleProperty(id, property){
      this.$emit('toggleProperty', {
        id: id,
        property: property
      })
    },
    downloadPdfItem(url){
      axios.post(url)
      .then(function (response) {
        if(response.data.success && response.data.url){
          window.open(response.data.url, '_blank');
        }
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    goToUrl(url){
      if(url){
        window.open(url, '_blank');
      }
    },
    openMoreModal (id) {
      this.$emit('more-modal', id)
    },
    paginationChangePage (page) {
      if (this.options.comingSoon) return

      this.$emit('change-page', page)
      this.$emit('scroll-top', this.$refs.tbl)
    },
    showNotificationDetails (type, notification) {
      if (type === 'update-profile') {
        this.$store.dispatch('notifications/viewUpdateProfileFields', notification)
      }
      if (type === 'update-business-information') {
        this.$store.dispatch('notifications/viewUpdatedBusinessInformationFields', notification)
      }
      if (type === 'update-logo') {
        this.$store.dispatch('notifications/viewUpdatedLogo', notification)
      }
      if (type === 'update-request') {
        this.$store.dispatch('notifications/updateRequest', notification)
      }
      if (type === 'apply-offer') {
        this.$store.dispatch('notifications/applyOffer', notification)
      }
      if (type === 'cancel-offer') {
        this.$store.dispatch('notifications/cancelOffer', notification)
      }
      if (type === 'update-compliance') {
        this.$store.dispatch('notifications/updateCompliance', notification)
      }
      if (type === 'create-service-chat') {
        this.$router.push({ name: "Chat", params: { chatId: notification.notification.data.chat_id } })
      }
      if (type === 'create-offer-chat') {
        this.$router.push({ name: "Chat", params: { chatId: notification.notification.data.chat_id } })
      }
    },
    sortBy (name) {
      if (this.options.sort.loading) return
      this.$emit('sort', {
        obj: this.options.objName,
        sort: name
      })
    },
    showUserBusinessOffers (id) {
      this.$emit('show-user-bos-offers', {
        id: id,
      })
    },
    showProcessSteps(id){
      this.$emit('showProcessSteps', {
        id: id,
      })
    },
    showComments(id){
      this.$emit('showComments', {
        id: id,
      })
    },
    gotoUserDetails (id) { 
      this.$router.push({ name: "CustomerDetail", params: { id: id }})
    },
    clickRow (row) {
      if (this.chatAppliedOfferType.length > 0) {
        for (var prop in this.options.body[row]) {
            if (Object.prototype.hasOwnProperty.call(this.options.body[row], prop)) {
                const p = this.options.body[row][prop]
                if (p.type && p.type == 'hidden-id') {
                  this.setChatOffer({
                    id: p.id,
                    type: this.chatAppliedOfferType,
                    time: +Date.now()
                  })
                  this.$emit('close',)
                }
            }
        }
      }
    },
    showChatRequests(userId) {
      this.$router.push({ name: "Requests", params: { id: userId }})
    },
    showChat (chatId) {
      this.$router.push({ name: "Chat", params: { chatId: chatId } })
    }
  },
  watch: {
    'myProfileForm.registration_country': function (countryId) {
      this.setCountryName(countryId)
    },
  }
}
</script>

<style lang="scss" scoped>
.transaction-item{
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px dashed #323940;
  &:last-child{
    border: none;
  }
}
.tbl {
  &_header {
    .col {
      // padding: 0.75rem 2px;
      padding: .5rem 2px;
      font-size: 14px;
      min-height: 3.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.gradient_btn {
        padding: 6px;
        position: relative;

        $margin: 4px;
        &::before {
          content: '';
          position: absolute;
          top: $margin; right: $margin; bottom: $margin; left: $margin;
          // background: linear-gradient(113.24deg, #1763fb 16%, #1763fb 106.71%);
          background: linear-gradient(113.24deg, #1763fb 16%, #3baab2 106.71%);
          filter: blur(1px);
          border-radius: 14px;
        }

        span {
          position: relative;
          width: 100%;
          min-height: 2.75rem;
          padding: .25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(-5deg, #1763fb -100%, #1763fb 100%);
          border-radius: 14px;
        }
      }

      .sort {
        font-size: 22px;
        line-height: 0.3;
        cursor: pointer;
        padding: 0 0.25rem;
        display: inline-flex;
        align-items: baseline;

        &--asc {
          transform: rotate(180deg);
          color: #1763fb;
        }

        &--desc {
          color: #1763fb;
        }

        &--load {
          color: rgba(128, 128, 128, 0.4);
          cursor: default;
        }
      }
    }
  }

  &_body {
    .tbl_row {
      border-bottom: 1px solid #323940;

      &:last-child {
        border: none;
      }

      .col {
        // position: relative;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        padding: 6px 4px;
        min-height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          word-break: break-word;
        }

        .box {
          &_btns {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            justify-content: space-evenly;
            flex-wrap: wrap;
          }
        }

        .btn {
          width: calc(100% - 1.5rem);
          color: #1763fb;
          padding: .5rem .75rem;
          background: rgba(23, 99, 251, 0.1);
          border-radius: 6px;

          &.info {
            width: 1rem;
            padding: 0;
            background: transparent;
          }

          &-edit,
          &-del {
            width: 1.75rem;
            height: 1.75rem;
            padding: .5rem;
            margin: .15rem;
            display: flex;
            align-items: center;
            justify-content: center;

            svg.img {
              width: 0.75rem;
              height: 0.75rem;
            }
            svg.big-img{
              width: 1.25rem;
              height: 1.25rem;
            }
          }

          &-del {
            ::v-deep svg.img path {
              fill: red;
            }
          }

          &_more {
            width: 2.75rem;
            height: 2.75rem;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        .show-user-business-offers {
          width: 100px;
        }

        .ddln {
          // position: absolute;
          // top: 50%;
          // right: 10%;
          // transform: translateY(-50%);
          width: calc(100% - 1.5rem);
          background: #393b41;
          border-radius: 6px;

          &_val {
            font-size: 24px;
            color: #fff;
            &.broken{
              color: red;
            }
          }

          &_desc {
            font-size: 14px;
          }
        }

        .del {
          .btn_close {
            width: 2rem;
            height: 2rem;
            padding: .25rem;
            background-color: #B03636;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 45%;
              height: 45%;
            }
          }
        }
      }

      &:hover {
        background-color: #3D444B;

        &:last-child {
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
        }
      }

      &-footer {
        justify-content: flex-start;
        padding-left: 20vw;

        .tp {
          font-size: 1.25rem;
          padding: .5rem 2rem;
          margin: .5rem;
          display: flex;
          align-items: baseline;
          justify-content: center;
          background-color: #272E35;
          border-radius: 6px;

          .val {
            color: #1763fb;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }

  &_footer {
    ::v-deep .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(23, 99, 251, 0.1);
      border-radius: 14px;

      .page-item {
        margin: .75rem .5rem;

        a {
          width: 2.25rem;
          max-width: 2.25rem;
          height: 2.25rem;
          max-height: 2.25rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: .5rem;
          font-size: 1.25rem;
          color: #1763fb;
          text-decoration: none;
          text-transform: uppercase;
          background-color: #2F363D;
          border-radius: 50%;

          .sr-only {
            display: none;
          }
        }

        &.active {
          a {
            color: #ffffff;
            background-color: #1763fb;
            font-weight: 800;
          }
        }
      }
    }
  }
}
svg.img {
  width: 2rem;
  height: 2rem;
  fill: #1763fb;
}
svg.big-img{
  width: 1.25rem !important;
  height: 1.25rem !important;
}
.request-table .tbl_header .col {
  font-size: 9px !important;
}

.request-table .tbl_body .tbl_row .col {
  font-size: 9px;
}

.rating-color {
    list-style: none;
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
    border-radius: 2rem;
    padding-top: 3px;
}

.rating_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--data {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 2rem;
    line-height: 1.2;
    cursor: pointer;
  }

  &--empty {
    width: 2.75rem;
    height: 2.75rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.notification {
    display: flex !important;
    width: 100%;
    margin: 1.5rem 0;
}

.notification span {
  justify-content: flex-start;
  padding: 0.25rem 0.75rem;
  color: white;
  font-size: 14px;
  background: linear-gradient(-5deg, #1763fb -100%, #1763fb 100%);
  box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
  border-radius: 14px;
  cursor: pointer;
}

.link {
  text-decoration: underline;
  color: #1763fb;
  cursor: pointer;
}
.decorate-circle{
  position: absolute !important;
  top: -2px;
  right: -2px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #1763fb;
  &-wrap{
    position: relative;
  }
}
.fake-link{
  text-decoration: underline;
}
.btn-match{
  position: relative;
  background: rgba(255, 165, 0, .1) !important;
  transform: scale(1.2);
  .match-text{
    position: absolute;
    color: #fff;
    font-size: 12px;
  }
  .match-count{
    position: absolute;
    color: #fff;
    bottom: -6px;
    right: -4px;
    font-size: 17px;
    font-weight: bold;
  }
}
</style>